@import './../../../../theme/style/theme';

$selected-major-height: 100px;

.selected-majors {
  .selected-majors__none-selected {
    display: table-cell;
    width: 180px;
    font-size: 14px;
    font-size: 14px;
    padding: 8px 10px;
    padding-right: 10px;
    height: 96px;
    font-weight: 300;
    overflow: visible;
    box-shadow: 0px 1px 4px 0 #5b5b5b5b;
    border: none;
    background: #fefefe;
    vertical-align: middle;

    color: $theme-text-color-light;
    text-align: center;
  }
  .selected-majors__header {
    font-size: $theme-header-text-size-default;
    font-weight: $theme-header-text-weight-default;
    letter-spacing: $theme-header-text-letter-spacing-default;
    padding-bottom: 10px;
  }
  .selected-majors__content-scrollbar {
    display: block;
    width: 100%;
    clear: both;
    height: 120px;
    overflow: auto;
    //position: relative;

    &.selected-majors__content-scrollbar--shown {
      > .selected-majors__content-scrollbar--indicator {
        display: table;
      }
    }

    > .selected-majors__content-scrollbar--indicator {
      position: absolute;
      top: 0px;
      height: $selected-major-height;
      width: 30px;
      display: none;
      background: #000000;
      opacity: 0.3;
      z-index: 10;

      &.selected-majors__content-scrollbar--indicator-left {
        left: 0px;
      }
      &.selected-majors__content-scrollbar--indicator-right {
        right: 0;
      }

      > .material-icons {
        display: table-cell;
        vertical-align: middle;
        color: #ffffff;
        font-size: 30px;
        text-shadow: 1px 1px 1px #000000;
      }
    }

    .selected-majors__content-wrapper {
      text-align: left;
      overflow-x: auto;
      overflow-y: hidden;
      height: $selected-major-height;
      display: table;
      width: 1%;
      max-width: 100%;
      table-layout: fixed;
    }
  }
}
