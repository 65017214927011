@import "../../../../theme/style/theme";

.major-details-specialisations {
  grid-column: 1/3;
  padding-right: 20px;
  font-size: 14px;
  padding-bottom: 20px;
  .major-details-specialisations-header {
    font-weight: bold;
    padding-bottom: 5px;
  }
  .major-details-specialisations-wrapper {
    .major-details-specialisations-specialisation {
      float: left;
      display: table;
      font-size: 14px;
      line-height: 16px;
      padding: 6px 12px;
      padding-right: 8px;
      background: #ffffff;
      border-left: 5px solid $theme-color-blue;
      margin-right: 8px;
      margin-top: 12px;
      font-weight: lighter;
      box-shadow: 0px 1px 0px $theme-outcome-bricks-box-shadow;
      color: $theme-color-blue;
      &.major-details-specialisations-specialisation--selected {
        .clickable {
          .major-details-specialisations-specialisation__selected-icon {
            &.material-icons {
              color: $theme-color-remove;
            }
          }
        }
      }
      .major-details-specialisations-specialisation-name {
        display: table-cell;
        vertical-align: middle;
        width: auto;
        padding-right: 10px;
      }
      .clickable {
        display: table-cell;
        .material-icons {
          vertical-align: middle;
          font-size: 20px;
        }
        .major-details-specialisations-specialisation__selected-icon {
          &.material-icons {
            color: $theme-color-select;
          }
        }
        .major-details-specialisations-specialisation__favourited-icon {
          &.material-icons {
            color: $theme-color-favourite;
            padding-right: 5px;
          }
        }
      }
    }
  }
}