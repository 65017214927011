@import "../../../../theme/style/theme";

.course-year {
  padding-bottom: 16px;
  display: block;

  .course-semester__no-subject-message {
    color: $theme-text-color-default;
    padding: 23px 20px;
    text-align: center;
    font-weight: lighter;
    font-size: 14px;
    height: 180px;
    overflow: hidden;
    .course-semester__no-subject-message__icons {
      display: table;
      width: 295px;
      table-layout: fixed;
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;
      .course-semester__no-subject-message__icon {
        display: table-cell;
        width: 50%;
        .course-semester__no-subject-message__icon-select {
          display: table;
          padding-right: 5px;
          .course-semester__no-subject-message__icon-pic {
            color: $theme-color-select;
            display: table-cell;
            vertical-align: middle;
          }
        }
        .course-semester__no-subject-message__icon-favourite {
          display: table;
          padding-left: 10px;
          .course-semester__no-subject-message__icon-pic {
            color: $theme-color-favourite;
            display: table-cell;
            vertical-align: middle;
          }
        }
        .course-semester__no-subject-message__icon-description {
          font-size: 12px;
          display: table-cell;
          vertical-align: middle;
          text-align: left;
          padding-left: 10px;
        }
      }
      .course-semester__no-subject-message__icon-spacer {
        display: table-cell;
        width: 1px;
        background: $theme-text-color-light;
      }
    }
  }

  .course-year__wrapper {
    position: relative;
    border-radius: 6px;
    padding-top: 2px;
    padding-left: 20px;
    padding-right: 10px;
    padding-bottom: 16px;
    background: #eaeaea;
    box-shadow: 0px 1px 0px 0px #b3b3b3;

    width: calc(100% - 1px);
    .course-year__header {
      height: 50px;
      font-size: 22px;
      font-weight: lighter;
      line-height: 50px;
      border-bottom: 1px solid $theme-rule-dark-color;
      position: relative;
    }
    .course-semester {
      &.course-semester--no-subjects {
        max-height: 0;
        -webkit-transition: all 200ms cubic-bezier(0.23, 1, 0.32, 1);
        transition: all 200ms cubic-bezier(0.23, 1, 0.32, 1);
      }
    }
  }

  &.course-year--no-subjects-selected-for-year {
    &:not(.course-year--drag-over) {
      .course-year__wrapper {
        padding-bottom: 0px;
        .course-year__header {
          border-bottom-width: 0;
        }
      }
    }
  }

  &.course-year--no-subjects-selected-for-course {
    &.course-year--first-year {
      .course-year__wrapper {
        padding-bottom: 0px;
        .course-year__header {
          border-bottom-width: 1px;
        }
      }
    }
  }

  &.course-year--drag-over {
    .course-year__wrapper {
      .course-semester {
        opacity: 1;
        &.course-semester--no-subjects {
          max-height: $theme-my-course-plans-item-height * 2;
          visibility: visible;
          -webkit-transition: all 200ms cubic-bezier(0.23, 1, 0.32, 1);
          transition: all 200ms cubic-bezier(0.23, 1, 0.32, 1);
        }
      }
    }
  }
}
