@import './../../../../theme/style/theme';

$breadcrumb-level-color: #F5A623;
$breadcrumb-location-color: #CEB4D3;
$breadcrumb-semester-color: #99C24D;
$breadcrumb-year-color: #4A90E2;

.subject-breadcrumb {
  width: 100%;
  overflow: hidden;
  .subject-breadcrumb__items {
    float: right;
    display: table;
    font-size: 12px;
    //font-weight: lighter;
    table-layout: fixed;
    .subject-breadcrumb__spacer {
      display: table-cell;
      width: 6px;
    }
    .subject-breadcrumb__item {
      height: 23px;
      display: table-cell;
      vertical-align: middle;
      border-radius: 12.5px;
      border: 1px solid $theme-border-color-dark;
      line-height: 23px;
      width: 40px;
      text-align: center;
      &.subject-breadcrumb__level {
        color: $breadcrumb-level-color;
        border-color: $breadcrumb-level-color;
      }
      &.subject-breadcrumb__location {
        color: $breadcrumb-location-color;
        border-color: $breadcrumb-location-color;
      }
      &.subject-breadcrumb__semester {
        color: $breadcrumb-semester-color;
        border-color: $breadcrumb-semester-color;
        .subject-breadcrumb__semester__summer,
        .subject-breadcrumb__semester__winter {
          height: 16px;
          width: 100%;
          background-position: center;
          background-size: 16px;
          background-repeat: no-repeat;
        }
        .subject-breadcrumb__semester__summer {
          background-image: url("../../../../theme/img/subject-breadcrumb/summer.svg");
        }
        .subject-breadcrumb__semester__winter {
          background-image: url("../../../../theme/img/subject-breadcrumb/winter.svg");
        }
      }
      &.subject-breadcrumb__year {
        color: $breadcrumb-year-color;
        border-color: $breadcrumb-year-color;
      }
    }
  }
}

.course-planner--Safari {

}
