@import "../../../../theme/style/theme";

.selected-subject {
  position: relative;
  font-size: 14px;
  border-top: 1px solid $theme-border-color-light;
  border-bottom: 1px solid $theme-border-color-light;
  //margin-top: -1px;
  background: $theme-my-course-plans-droppable-background-color;
  width: 100%;

  margin-left: 0px;

  -webkit-transition: margin-left 200ms linear;
  transition: margin-left 200ms linear;

  animation: drop-in-frames 200ms cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;

  &.select-subject--select-animation-off {
    animation: none;
    transform: none;
  }
  &.selected-subject--not-mounted {
    //margin-left: -100%;
  }
  &.selected-subject--unknown {
    .collapsible {
      background-color: $theme-my-course-plans-subject-met-background-color;
    }
  }
  &.selected-subject--unselecting {
    height: $theme-my-course-plans-item-height;
    margin-left: -100%;
  }
  &.selected-subject--met {
    .collapsible {
      background-color: $theme-my-course-plans-subject-met-background-color;
    }
  }
  &.selected-subject--open {
    .collapsible {
      background-color: $theme-my-course-plans-subject-open-background-color;
    }
  }
  &.selected-subject--unmet {
    background-color: $theme-my-course-plans-subject-unmet-background-color;
  }
  > .collapsible {
    top: 0px;
  }
  &.selected-subject--html-dragging {
    height: 0 !important;
  }
  &.selected-subject--dragging {
  }
  &.selected-subject--html-drag-over-top-first-visible-on-list {
    position: relative;
    height: $theme-my-course-plans-item-height * 2 !important;
    > .collapsible {
      position: absolute;
      top: $theme-my-course-plans-item-height;
      border-top: 1px solid $theme-border-color-light;
      border-bottom: 1px solid $theme-border-color-light;
      transition: all, 300ms;
    }
  }
  &.selected-subject--html-drag-over-top {
    position: relative;
    height: $theme-my-course-plans-item-height * 2 !important;
    > .collapsible {
      position: absolute;
      top: 0;
      border-top: 1px solid $theme-border-color-light;
      border-bottom: 1px solid $theme-border-color-light;
      transition: all, 300ms;
    }
  }
  &.selected-subject--html-drag-over-bottom-first-visible-on-list {
    position: relative;
    height: $theme-my-course-plans-item-height * 2 !important;
    > .collapsible {
      position: absolute;
      top: 0px;
      border-bottom: 1px solid $theme-border-color-light;
      transition: all, 300ms;
    }
  }
  &.selected-subject--html-drag-over-bottom {
    position: relative;
    height: $theme-my-course-plans-item-height * 2 !important;
    > .collapsible {
      position: absolute;
      top: 0px;
      border-bottom: 1px solid $theme-border-color-light;
      transition: all, 300ms;
    }
  }

  &.selected-subject--met {
    .collapsible__header__text {
      box-shadow: 0px 2px 4px 0 #000000;
    }
  }
  &.selected-subject--unmet {
    .collapsible__header__text {
      box-shadow: 0px 2px 4px 0 #000000;
    }
  }
  &.selected-subject--open {
    .collapsible__header__text {
      box-shadow: 0px 2px 4px 0 #000000;
    }
  }
  &:last-child {
    border-bottom: none; //1px solid $theme-border-color-dark;
  }

  > .collapsible {
    border: none;
    > .collapsible__header {
      table-layout: fixed;
      > .collapsible__header__text {
        padding: 0px;
        width: 100%;
        height: auto;
        font-size: inherit;
        cursor: inherit;
        position: relative;
        &:before {
          display: none;
        }
        &.collapsible__header__text--hide {
          box-shadow: none;
        }
      }
      > .collapsible__header__actions {
        display: none;
      }
    }
    > .collapsible__content {
      border-top: none;
    }
  }
}