@import "../../../../../theme/style/theme";

.selected-major {
  cursor: pointer;
  display: table-cell;
  width: 180px;
  font-size: 14px;
  color: $theme-color-blue;
  font-size: 14px;
  padding: 8px 10px;
  padding-right: 10px;
  height: 96px;
  background: #ffffff;
  font-weight: 300;
  overflow: visible;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)) /*, url('../../../../theme/img/majors/AG-SCIENCE_LR.jpg')*/;
  background-size: 100%;
  box-shadow: 0px 1px 4px 0 #5b5b5b5b;

  .selected-major__wrapper {
    position: relative;
    height: 100%;
    overflow: visible;
    .selected-major__wrapper__content-wrapper {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      clear: both;

      .selected-major__wrapper__content-wrapper__text {
        vertical-align: top;
        overflow: hidden;
        color: #ffffff;
        text-shadow: 1px 1px #000000;
        padding-right: 25px;
        position: absolute;
      }
      .selected-major__wrapper__content-wrapper__specialisation {
        position: absolute;
        bottom: 0;
        line-height: 14px;
        font-size: 12px;
        color: #fff;
        text-align: left;
        text-shadow: 1px 1px #000000;
        font-weight: lighter;
        overflow: hidden;
      }
      > .clickable {
        position: absolute;
        line-height: 14px;
        right: -5px;
        top: -3px;
        background: transparent;
        .selected-major__wrapper__content-wrapper__unselected-major-icon {
          color: #ffffff;
          width: 27px;
          height: 27px;
          line-height: 27px;
        }
      }
    }
    .selected-major__wrapper__availability {
      height: 30px;
      width: 30px;
      border-radius: 15px;
      position: absolute;
      right: -15px;
      bottom: -15px;
      background: transparent;
      .material-icons {
        font-size: 30px;
        text-shadow: 3px 2px 1px #eee;
      }
      .selected-major__wrapper__availability--warning {
        color: $theme-my-major-availability-maybe-possible-color;
      }
      .selected-major__wrapper__availability--error {
        color: $theme-my-major-availability-not-possible-color;
      }
    }
  }
}

.selected-major-spacer {
  display: table-cell;
  width: 15px;
  background: transparent;
}


.course-planner--Firefox {
  * {
    .selected-major {
      .selected-major__wrapper {
        .selected-major__wrapper__availability {
          bottom: -18px;
          right: -18px;
        }
      }
    }
  }
}