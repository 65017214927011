@import '../../../../theme/style/theme';


.subjects-by-category {
  padding-bottom: 20px;

  .subjects-by-category__header {
    //text-transform: uppercase;
    padding-bottom: 10px;
    padding-right: 7px;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 0.5px;
    .subjects-by-category__header__info {
      color: #777777;
    }
    .subjects-by-category__header__add-all {
      float: right;
      font-weight: 300;
      color: $theme-color-select;
      display: table;
      font-size: 14px;
      &.subjects-by-category__header__add-all--selected {
        color: $theme-color-remove;
      }
      .subjects-by-category__header__add-all-text {
        display: table-cell;
        vertical-align: middle;
        padding-right: 5px;
      }
      .material-icons {
        display: table-cell;
        vertical-align: middle;
        margin-top: -3px;
        line-height: 19px;
        padding-right: 1px;
      }
    }
  }
}