// the padding for collapsible-cmpt
$collapsible-item-padding: 10px;
$collapsible-item-padding-large: 20px;
$collapsible-item-arrow-size: 6px;
$collapsible-item--indent-padding: 38px;

$theme-alter-item-background-color: #F2F2F2;
$theme-alter-item-border-color: #B0B0B0;
$theme-item-height-default: 40px;
$theme-item-line-height-default: 38px; // to allow 2px for the border (top and bottom)

$theme-border-color-dark: #cccccc;
$theme-border-color-light: #e3e3e3;
$theme-loading-overlay-color: #ffffff;
$theme-loading-overlay-opacity: 0.4;

$theme-header-text-size-default: 16px;
$theme-header-text-weight-default: bold;
$theme-header-text-letter-spacing-default: 0.5px;

$theme-text-size-default: 16px;
$theme-text-weight-medium: 300;
$theme-text-weight-lighter: lighter;
$theme-text-color-default: #4a4a4a;
$theme-text-color-light: #8a8a8a;
$theme-color-blue: #0769af;
$theme-link-color: #0769af;

$theme-color-green: #98ce62;

$theme-dialog-indicator-size: 11px;
$theme-dialog-background-color: #2e4057;

$theme-color-select: #99c24d;
$theme-color-favourite: #E64959;
$theme-color-remove: #b0b0b0;
$theme-color-compulsory: #007680;
$theme-color-elective: #00BACC;
$theme-color-breadth: #9b9bdd;

$theme-my-course-plans-background-color: #f1f1f1;
$theme-my-course-plans-progress-background-color: #2e4057;
$theme-my-course-plans-item-background: #dedede;
$theme-my-course-plans-course-subjects-background-color: #ffffff;
$theme-my-course-plans-item-height: 45px;
$theme-my-course-plans-item-padding-left-right: 20px;
$theme-my-course-plans-droppable-background-color: #f9f9f9;

$theme-my-course-plans-subject-met-color: #4A90E2;
$theme-my-course-plans-subject-met-background-color: #ffffff;
$theme-my-course-plans-subject-message-panel-met-color: #D0DCE6;

$theme-my-course-plans-subject-unmet-color: #E64959;
$theme-my-course-plans-subject-unmet-background-color: #FFEFEF;
$theme-my-course-plans-subject-message-panel-unmet-color: #E0C7C7;

$theme-my-course-plans-subject-open-color: #F2A900;
$theme-my-course-plans-subject-open-background-color: #FFFBF0;
$theme-my-course-plans-subject-message-panel-open-color: #EBE489;

$theme-outcome-background-color: #f5f5f5;
$theme-outcome-header-background-color: #e1e1e1;
$theme-outcome-bricks-box-shadow: #b0b0b0;

$theme-browse-subjects-background-color: #f1f1f1;
$theme-browse-subjects-header-background-color: #e1e1e1;

$theme-rule-dark-color: #939393;
$theme-rule-light-color: #ffffff;


$theme-my-major-availability-maybe-possible-color: #e39a00;
$theme-my-major-availability-not-possible-color: #E64959;

$theme-my-course-plans-course-plan-met-color: #99c24d;
$theme-my-course-plans-course-plan-unmet-color: #E64959;
