@import '../../../../theme/style/theme';

$TEXT_BORDER_COLOR: #3975b7;


.available-major {
  float: left;
  width: auto;
  cursor: pointer;
  color: $theme-color-blue;
  font-size: 14px;
  line-height: 16px;
  background: #ffffff;
  border-left: 8px solid $theme-color-blue;
  margin-right: 10px;
  margin-top: 12px;
  font-weight: lighter;
  box-shadow: 0px 1px 2px rgba(155, 155, 155, 50%);

  &[data-availability-score="5"] {
    border-left: 8px solid $TEXT_BORDER_COLOR;
    a {
      color: $TEXT_BORDER_COLOR;
    }
  }
  &[data-availability-score="4"] {
    border-left: 8px solid #819fc1;
    a {
      color: #819fc1;
    }
  }
  // timed out
  &[data-availability-score="3"] {
    border-left: 8px solid #a7bad1;
    a {
      color: #a7bad1;
    }
  }
  &[data-availability-score="2"] {
    border-left: 8px solid #c5d0d8;
    a {
      color: #c5d0d8;
    }
  }
  // unavailable
  &[data-availability-score="1"] {
    border-left: 8px solid #d3d3d3;
    a {
      color: #d3d3d3;
    }
  }

  //&.available-major--selected {
  //  > .clickable {
  //    color: $theme-color-select;
  //  }
  //}
  //&.available-major--favourited {
  //  > .clickable {
  //    color: $theme-color-favourite;
  //  }
  //}
  //&.available-major--favourited.available-major--selected {
  //  .clickable {
  //    color: $theme-color-select;
  //  }
  //}
  .available-major__wrapper {
    .clickable {
      padding: 6px 12px;
      display: inline-block;
      &:nth-of-type(2) {
        padding: 0px;
        width: 30px;
        height: 28px;
        text-align: center;
        border-left: 1px solid #D8D8D8;
      }
      .material-icons {
        font-size: 21px;
        padding: 0;
        margin: 0;
        height: 30px;
        width: 28px;
        line-height: 30px;
        vertical-align: middle;
        text-align: center;
      }
    }
  }
}

.course-planner--Firefox {
  .available-major {
    .available-major__wrapper {
      .material-icons {
        line-height: 28px;
      }
    }
  }
}
