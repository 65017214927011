@import '../../../../theme/style/theme';

.search-box {
  color: $theme-text-color-light;
  background: #ffffff;
  position: relative;
  input.search-box__input[type="search"] {
    -webkit-appearance: textfield;
  }
  .search-box__input {
    border: 1px solid $theme-border-color-light;
    width: 100%;
    padding: 6px 30px;
    padding-left: 33px;
    line-height: 20px;
    font-size: 16px;
    background: transparent;
    margin: 0px;
    &:focus {
      outline: 0;
    }
  }
  .search-box__search.material-icons {
    position: absolute;
    margin-top: 5px;
    margin-left: 5px;
    z-index: 1;
    z-index: 1;
  }
  .search-box__clear.material-icons {
    position: absolute;
    right: 8px;
    font-size: 20px;
    top: 7px;
    cursor: pointer;
  }
}
