@import './../../../../theme/style/theme';

.input-field {
  width: 100%;
  &.input-field--error {
    input {
      border: 1px solid red;
      text-decoration: dashed;
    }
  }
}
