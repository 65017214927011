@import "../../../../theme/style/theme";

$text-color: #ffffff;
$background-color: $theme-dialog-background-color;

.subject-details {
  h1, h2, h3, h4, h5, h6 {
    color: #ffffff;
  }
  background: $theme-browse-subjects-background-color;
  padding-bottom: $theme-dialog-indicator-size;
  position: relative;
  .subject-details__indicator {
    position: relative;
    z-index: 1;
    border-left: $theme-dialog-indicator-size solid transparent;
    border-right: $theme-dialog-indicator-size solid transparent;
    border-bottom: $theme-dialog-indicator-size solid $background-color;
    left: 74px;
    margin-left: $theme-dialog-indicator-size * -1;
    margin-top: 1px;
    width: $theme-dialog-indicator-size;
    height: $theme-dialog-indicator-size;
  }
  .subject-details__subject-content-wrapper {
    min-height: 280px;
    padding: 20px;
    padding-top: 15px;
    background-color: $background-color;
    font-weight: lighter;
    color: $text-color;

    display: block;
    grid-template-rows: auto minmax(30px, auto);

    .subject-details__subject-header {
      padding-bottom: 16px;
      .subject-details__subject-header__code-and-name {
        display: grid;
        grid-template-columns: min-content auto min-content;
        .subject-details__subject-details__code {
          font-size: $theme-header-text-size-default;
          font-weight: $theme-header-text-weight-default;
          letter-spacing: $theme-header-text-letter-spacing-default;
          font-weight: bold;
          padding-right: 8px;
          white-space: nowrap;
          line-height: 30px;
        }
        .subject-details__subject-details__name {
          line-height: 30px;
          font-size: $theme-header-text-size-default;
          font-weight: $theme-header-text-weight-default;
          letter-spacing: $theme-header-text-letter-spacing-default;
          white-space: nowrap;
        }
      }
      .subject-details__subject-header__icons {
        height: 40px;
        display: table;
        table-layout: fixed;
        width: 40px;
        position: absolute;
        right: 0px;
        top: 12px;
        .clickable {
          display: table-cell;
          width: 24px;
          text-align: center;
          vertical-align: middle;
          .subject-details__subject-header__icons__icon__close {
            width: 40px;
            height: 40px;
            line-height: 40px;
            color: #ffffff;
            font-size: 20px;
            font-weight: bold;
            background: #576679;
          }
        }
      }
    }

    .subject-details__subject-content {
      padding-right: 20px;
      width: 100%;
      table-layout: fixed;
      .subject-details__subject-content-divider {
        display: table-cell;
        width: 1px;
        padding: 0 1px;
        background: $text-color;
      }
      .subject-details__subject-details__overview {
        padding-top: 5px;
        display: table-cell;
        width: 50%;
        font-size: 14px;
        text-align: justify;
        overflow: hidden;
        padding-right: 20px;
        .subject-details__subject-details__overview-title {
          padding-bottom: 10px;
          font-weight: bold;
        }
        .subject-details__subject-details__more {
          display: block;
          width: 100%;
          color: $text-color;
          font-weight: normal;
          padding-top: 10px;
          text-decoration: underline;
        }
        .subject-details__subject-details__overview__debug-panel {
          padding: 10px;
          li {
            padding: 5px;
            border: 1px solid #ffffff;
          }
        }
      }

      .subject-details__subject-eligibility-and-requirements {
        padding-top: 5px;
        display: table-cell;
        width: 50%;
        padding-left: 20px;
        font-size: 14px;

        .subject-details__subject-eligibility-and-requirements-title {
          font-weight: bold;
        }

        .eligibility_and_requirements {
          a {
            color: $text-color;
            text-decoration: underline;
            font-weight: normal;
          }
          p {
            padding: 6px 0px;
          }
          p.OR, p.BOTH {
            display: inline-block;
          }
          p.INDENT {
            padding-left: 20px;
          }
          ul.INDENT {
            padding-left: 20px;
            padding-bottom: 10px;
          }
          h3 {
            font-size: 14px;
            font-weight: bold;
            padding: 0px 0px;
            padding-top: 20px;
            &:first-child {
              padding-top: 11px;
            }
          }
          #prerequisites {
            color: $text-color;
          }
          .eligibility {
            width: 100%;
            text-align: left;
            margin-left: 20px;
            td {
              padding: 3px;
            }
            td:first-child {
              font-weight: lighter;
              width: 80px;
            }
            td:last-child {
              a {
                pointer-events: none;
                text-decoration: none;
                font-weight: lighter;
              }
            }
          }
        }
      }
    }
  }
}
