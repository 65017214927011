@import '../../../../../../../theme/style/theme';

.subject-header-category {
  position: relative;
  height: 100%;
  &:before {
    content: " ";
    width: 10px;
    height: calc(100% - 4px);
    position: absolute;
    left: 0;
    top: 0;
    display: table-cell;
  }
  &.subject-header-category--selected:after {
    content: " ";
    position: absolute;
    background: #ffffff;
    width: 100%;
    height: 37px;
    top: 0px;
    left: 0px;
    opacity: 0.6;
    z-index: 1;
  }
  &.subject-header-category--expand {
    text-align: center;
    line-height: $theme-item-height-default;
    vertical-align: middle;
    padding-left: 12px;
  }
  &.subject-header-category--collapse {
    text-align: center;
    line-height: $theme-item-height-default;
    vertical-align: middle;
    padding-left: 12px;
  }
  &.subject-header-category--compulsory:before {
    background-color: $theme-color-compulsory;
  }
  &.subject-header-category--elective:before {
    background-color: $theme-color-elective;
  }
  &.subject-header-category--breadth:before {
    background-color: $theme-color-breadth;
  }
}
