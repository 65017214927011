@import '../../../../theme/style/theme';


.message {
  border: 1px solid transparent;
  border-radius: 3px;
  margin-bottom: 10px;

  &.message--info {
    background-color: #dff0d8;
    border-color: #d0e9c6;
    color: #3c763d;
  }
  &.message--alert {
    background-color: #d9edf7;
    border-color: #bcdff1;
    color: #31708f;
  }
  &.message--warn {
    background-color: #fffceb;
    border-color: #ffeeba;
    color: #dd9500;
  }
  &.message--error {
    background-color: #ffdee1;
    border-color: #f5c6cb;
    color: #E64959;
  }

  .message__text {
    float: left;
    padding: 12px 20px;
  }
  .message__close {
    padding: 11px 15px;
    float: right;
    padding-bottom: 6px;
    cursor: pointer;
  }
}
