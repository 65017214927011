@import 'theme';

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

@font-face {
  font-family: 'roboto';
  src: url('../fonts/roboto/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'roboto';
  src: url('../fonts/roboto/Roboto-Light.ttf');
  font-weight: 200;
}

@font-face {
  font-family: 'roboto';
  src: url('../fonts/roboto/Roboto-Medium.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'roboto';
  src: url('../fonts/roboto/Roboto-Bold.ttf');
  font-weight: bold;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  line-height: 1.35em;
}

body {
  height: auto;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0px;
  color: $theme-text-color-default;
}

input, area {
  box-sizing: border-box;
  outline: 0;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
div {
  overflow: hidden;
}
b {
  font-weight: bold;
}
// css tooltip
div.__react_component_tooltip.type-dark {
  background-color: #666666!important;
  padding: 8px 16px;
  font-weight: lighter;
  font-size: 12px;
  border-radius: 2px;
  white-space: nowrap;
}

// css spinner
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: $theme-color-blue;
  animation-timing-function: linear;
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 1s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 1s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 34px;
  animation: lds-ellipsis2 1s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 1s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.horizontal-shaking {
  animation: horizontal-shake-frames 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes horizontal-shake-frames {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.rotational-shaking {
  animation: rotational-shake-frames 200ms cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@-webkit-keyframes rotational-shake-frames {
  0% {
    -webkit-transform-origin:center center;
    -webkit-transform: rotate(3deg);
    transform-origin:center center;
    transform: rotate(3deg);
  }
  25% {
    -webkit-transform-origin:center center;
    -webkit-transform: rotate(-3deg);
    transform-origin:center center;
    transform: rotate(-3deg);
  }
  50% {
    -webkit-transform-origin:center center;
    -webkit-transform: rotate(3deg);
    transform-origin:center center;
    transform: rotate(3deg);
  }
  75% {
    -webkit-transform-origin:center center;
    -webkit-transform: rotate(-3deg);
    transform-origin:center center;
    transform: rotate(-3deg);
  }
  100% {
    -webkit-transform-origin:center center;
    -webkit-transform: rotate(0deg);
    transform-origin:center center;
    transform: rotate(0deg);
  }
}

@-webkit-keyframes drop-in-frames {
  0% {
    -webkit-transform-origin:center center;
    -webkit-transform: scale(1.05);
    transform-origin:center center;
    transform: scale(1.05);
  }
  95% {
    -webkit-transform-origin:center center;
    -webkit-transform: scale(0.90);
    transform-origin:center center;
    transform: scale(0.90);
  }
  100% {
    -webkit-transform-origin:center center;
    -webkit-transform: scale(1);
    transform-origin:center center;
    transform: scale(1);
  }
}

