@import "../../../../theme/style/theme";

.major-details-actions {
  padding-top: 5px;
  display: table;
  table-layout: fixed;
  float: right;
  .clickable {
    display: table-cell;
    width: 24px;
    text-align: center;
    vertical-align: middle;
    .major-details-actions__action {
      width: 20px;
      height: 20px;
      line-height: 20px;
    }
    .major-details-actions__action__select {
      color: $theme-color-select;
      font-size: 20px;
      &.major-details-actions__action__select--selected {
        color: $theme-color-remove;
      }
    }
    .major-details-actions__action__favourite {
      color: $theme-color-favourite;
      font-size: 18px;
    }
    .major-details-actions__action__close {
      color: $theme-color-remove;
      font-size: 20px;
      font-weight: bold;
    }
  }
  .major-details-actions__action__spacer {
    width: 10px;
  }
}