@import "../../../../../theme/style/theme";

.subjects-by-category-level-group {
  .subjects-by-category-level-group__level {
    float: left;
    font-size: 14px;
    font-weight: 300;
    padding-right: 10px;
    color: #8a8a8a;
  }

  // TODO: unwind this CSS mess
  .collapsible-list {
    .collapsible-list__list-items {
      background-color: transparent;
      .collapsible-list-item {
        background-color: #ffffff;
        margin-bottom: 3px;
      }
    }
  }

  .collapsible .collapsible__header .collapsible__header__text .subject-breadcrumb {
    display: table-cell;
    vertical-align: middle;
  }

  .collapsible .collapsible__header .collapsible__header__actions {
    padding-right: 0px;
  }
}