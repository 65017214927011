@import "../../../../../theme/style/theme";


.selected-subject-info-panel {
  padding: 20px;
  padding-left: 50px;
  font-size: 14px;
  //height: $theme-my-course-plans-item-height * 3;
  .selected-subject-info-panel__headline {
    font-weight: bold;
    padding-bottom: 8px;
  }
  .selected-subject-info-panel__details {
    font-weight: lighter;
    .selected-subject-info-panel__details__more {
      display: block;
      text-decoration: underline;
      padding-top: 10px;
      font-weight: normal;
    }
    .selected-subject-info-panel__details__items {
      .selected-subject-info-panel__details__item {
        list-style-type: none;
        padding-bottom: 3px;
        .selected-subject-info-panel__details__item-heading {
          display: block;
        }
        .selected-subject-info-panel__details__item-sub {
          display: block;
          padding-left: 10px;
        }
      }
    }
  }
  &.selected-subject-info-panel--met {
    background-color: $theme-my-course-plans-subject-message-panel-met-color;
  }
  &.selected-subject-info-panel--unmet {
    background-color: $theme-my-course-plans-subject-message-panel-unmet-color;
  }
  &.selected-subject-info-panel--open {
    background-color: $theme-my-course-plans-subject-message-panel-open-color;
  }
}