@import '../../../../../../theme/style/theme';

.subject-header {
  display: table;
  table-layout: fixed;
  overflow: hidden;
  position: relative;
  &.subject-header--selected:before {
    content: " ";
    position: absolute;
    background: #ffffff;
    width: 100%;
    height: 37px;
    top: 0px;
    left: 0px;
    opacity: 0.6;
    z-index: 1;
  }
  &:hover {
    .subject-header__tooltip {
      display: inline-block;
    }
  }
  .subject-header__tooltip {
    display: none;
    position: absolute;
    top: 8px;
    margin-right: 10px;
    font-size: 12px;
    padding-left: 20px;
    color: #aaaaaa;
    .subject-header__tooltip__icon {
      color: $theme-color-select;
      position: relative;
      font-size: 19px;
      line-height: 18px;
      top: 5px
    }
  }
  .subject-header__text {
    display: table-cell;
    vertical-align: middle;
    font-size: 14px;
    height: $theme-item-height-default;
  }
}
