@import '../../../theme/style/theme';

.my-majors {
  width: 100%;
  display: grid;
  grid-template-columns: min-content auto;
  overflow: hidden;
  position: relative;

  b {
    font-weight: bold!important;
  }
  .my-majors__my-major-group {
    &.my-majors__my-major-group--no-major-selected {
      display: none;
    }
  }
  .my-majors__selected-major {
    min-width: 220px;
    padding-right: 30px;
    .selected-majors__content-scrollbar {
      overflow: hidden;
    }
  }
  .my-majors__favourited-majors {
    min-width: 280px;
    width: auto;
    &.my-majors__favourited-majors--detail-shown-within {
      .selected-majors__content-scrollbar {
        overflow: hidden;
      }
    }
  }
  .my-majors__my-major-details {
    grid-column: 1/3;
    margin-top: -18px;
    .major-details__indicator {
      //margin-left: 0px;
    }
  }
}
