@import "../../theme/style/theme";

.tools {
  margin: 0 auto;
  margin-top: 10px;
  width: 93%;
  position: relative;
  .tools__icons {
    width: auto;
    display: table;
    margin: 0 auto;

    .clickable, a {
      display: table-cell;
      .tools__icons__icon {
        -webkit-transition: all 200ms ease-in;
        transition: all 200ms ease-in;
        &.tools__icons__icon--pressed {
          -webkit-transition: all 100ms ease-out;
          transition: all 100ms ease-out;
          background-color: #e1e1e1;
          border-radius: 5px;
        }
        &:hover {
          transform: scale(1.2);
        }
      }
      input {
        position: absolute;
      }
      .material-icons {
        padding: 15px 35px;
        font-size: 30px;
        color: #2e4057;
        padding-bottom: 5px;
      }
      .tools__tools__icons__icon-text {
        font-size: 12px;
        text-align: center;
        padding-bottom: 15px;
        color: #6a6a6a;
        text-transform: uppercase;
      }
    }
  }
}
