@import '../../../../theme/style/theme';

.collapsible-list {
  position: relative;
  &.loading {
    pointer-events: none;
    overflow: hidden;
    .collapsible-list__overlay,
    .collapsible-list__overlay-icon {
      display: block;
    }
  }
  .collapsible-list__list-items {
    position: relative;
    min-height: $theme-item-height-default;
    //max-height: $theme-item-height-default * 11;
    background-color: #ffffff;
    overflow: auto;
  }
}
