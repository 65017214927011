@import "../../../../theme/style/theme";

.favourited-subjects {
  display: block;
  position: relative;
  border-radius: 6px;
  padding-top: 5px;
  padding-left: 20px;
  padding-right: 10px;
  padding-bottom: 16px;
  background: #eaeaea;
  box-shadow: 0px 1px 0px 0px #b3b3b3;
  width: calc(100% - 1px);
  z-index: 1;

  &.favourited-subjects--no-subjects {
    &:not(.favourited-subjects--drag-over) {
      padding-bottom: 0px;
      .favourited-subjects__header {
        border-bottom: none;
        margin-bottom: 0px;
      }
      .favourited-subjects__subjects {
        min-height: 0;
      }
    }
    .favourited-subjects__header {
      .favourited-subjects__header__clear {
        .favourited-subjects__header__clear__wrapper {
          display: none;
        }
      }
    }
  }

  .favourited-subjects__header {
    height: 50px;
    font-size: 22px;
    font-weight: lighter;
    line-height: 50px;
    border-bottom: 1px solid $theme-rule-dark-color;
    margin-bottom: 14px;
    display: table;
    width: 100%;

    .favourited-subjects__header__text {
      display: table-cell;
      vertical-align: middle;
    }
    .favourited-subjects__header__clear {
      font-weight: lighter;
      font-size: 12px;
      display: table-cell;
      vertical-align: middle;
      text-align: right;
      width: 1%;
      .favourited-subjects__header__clear__wrapper {
        display: table;
        line-height: 24px;
        color: $theme-text-color-light;
        .favourited-subjects__header__clear__text {
          display: table-cell;
          vertical-align: middle;
          line-height: 24px;
        }
        > .material-icons {
          //display: table-cell;
          display: none;
          vertical-align: middle;
        }
      }
    }
  }

  .favourited-subjects__subjects {
    min-height: $theme-my-course-plans-item-height;
    border-bottom: 1px solid $theme-border-color-dark;
    background: $theme-my-course-plans-course-subjects-background-color;

    &.favourited-subjects--subjects--draggable-over {
      position: relative;
      background: $theme-my-course-plans-droppable-background-color;
      &:before {
        content: " ";
        position: absolute;
        width: 100%;
        height: 100%;
        background: #ffffff;
        opacity: 0.5;
      }
    }
  }

  .selected-subject {
    > .collapsible {
    }
    &.selected-subject--html-dragging {
    }
    &.selected-subject--dragging {
    }
    &.selected-subject--html-drag-over-top-first-visible-on-list {
      position: relative;
      height: $theme-my-course-plans-item-height !important;
      > .collapsible {
        position: relative;
        top: auto;
        transition: none;
      }
    }
    &.selected-subject--html-drag-over-top {
      position: relative;
      height: $theme-my-course-plans-item-height !important;
      > .collapsible {
        position: relative;
        top: auto;
        transition: none;
      }
    }
    &.selected-subject--html-drag-over-bottom-first-visible-on-list {
      position: relative;
      height: $theme-my-course-plans-item-height !important;
      > .collapsible {
        position: relative;
        top: auto;
        transition: none;
      }
    }
    &.selected-subject--html-drag-over-bottom {
      position: relative;
      height: $theme-my-course-plans-item-height !important;
      > .collapsible {
        position: relative;
        top: auto;
        transition: none;
      }
    }
  }
}
