@import "./../../../theme/style/theme";

$start-drag-color-background: #f9f9f9;
$start-drag-color-text: #bfbfbf;
$start-drag-opacity: 0.3;

.selected-subjects {
  overflow: hidden;
  background: #ffffff;
  margin-top: -1px;
  width: 420px;
  padding: 16px;
  padding-bottom: 200px;
  &.selected-subjects--html-dragging-mode {
    .course-semester__no-subject-message {
      display: none;
    }
    .course-year--no-subjects-selected-for-year {
      &:not(.course-year--drag-over) {
        .course-year__wrapper {
          padding-bottom: 0px;
          .course-year__header {
            border-bottom: none;
          }
        }
      }
    }
    .course-year:not(.course-year--drag-over) {
      .course-year__wrapper {
        color: $start-drag-color-text;
        background: $start-drag-color-background;
        .course-year__header {
          border-bottom-color: $start-drag-color-text;
        }
        .course-semester {
          opacity: $start-drag-opacity;
        }
      }
    }
    .favourited-subjects:not(.favourited-subjects--drag-over) {
      background: $start-drag-color-background;
      color: $start-drag-color-text;
      .favourited-subjects__header {
        border-bottom-color: $start-drag-color-text;
      }
      .favourited-subjects__subjects {
        opacity: $start-drag-opacity;
      }
    }

    .selected-subject {
      height: $theme-my-course-plans-item-height;
      overflow: hidden;
      -webkit-transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
      transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
      * {
        pointer-events: none;
      }
    }
  }

  &.selected-subjects--html-dragging-mode.selected-subjects--react-dnd {
    .course-semester__no-subject-message {
      display: none;
    }

    .selected-subject {
      height: auto;
      overflow: hidden;
      -webkit-transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
      transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
      * {
        pointer-events: auto;
      }
    }
  }
}
