@import '../../../../../../../theme/style/theme';

.subject-header-actions {
  display: table;
  width: 100%;
  table-layout: fixed;
  .clickable {
    display: table-cell;
    width: 40px;
    height: 40px;
    line-height: 10px;
    vertical-align: middle;
    text-align: center;
    .subject-header-actions__icon {
      font-size: 24px;
      color: #ffffff;
      text-align: center;
      width: 20px;
      height: 20px;
      line-height: 20px;
      margin-top: 1px;
      vertical-align: middle;
      &.subject-header-actions__icon__select {
        width: 40px;
        height: 40px;
        line-height: 40px;
        background: $theme-color-select;
        &.subject-header-actions__icon__select--selected {
          background: $theme-color-remove;
        }
      }
      &.subject-header-actions__icon__favourite {
        font-size: 18px;
        color: $theme-color-favourite;
      }
    }
  }
  .subject-header-actions__icon__spacer {
    display: table-cell;
    width: 5px;
  }
}
