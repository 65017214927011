@import './../../../theme/style/theme';

.course-eligibility {
  border-bottom: 1px solid #cccccc;
  .course-eligibility__add-course-eligibility {
    padding: 6px 10px;
    padding-top: 10px;
    display: table;
    table-layout: fixed;
    width: 100%;
    background: #bebebe;
    background: #f1f1f1;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    &.course-eligibility__add-course-eligibility--invalid {
      .course-eligibility__add-course-eligibility__add-button {
        .clickable {
          font-weight: lighter;
          color: #8a8a8a;
          pointer-events: none;
        }
      }
    }
    .ant-select {
      .ant-select-selection {
        border: 1px solid #e1e1e1;
      }
    }
    .course-eligibility__add-course-eligibility__item {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      font-size: 12px;
      position: relative;
      &.course-eligibility__add-course-eligibility__name {
        .ant-select {
          font-size: 12px;
          border-radius: 0px;
          .ant-select-selection {
            border-radius: 0px;
            width: 285px;
            .ant-select-selection-selected-value {
              line-height: 32px;
            }
            input {
              padding-left: 0;
              padding-right: 0px;
            }

          }
        }
        .ant-select-dropdown {
          position: fixed !important;
          text-align: left;
          border-radius: 0;
          .ant-select-dropdown-menu {
            .ant-select-dropdown-menu-item {
              font-size: 12px;
              &:first-child, :last-child {
                border-radius: 0;
              }
            }
          }
        }
      }
      &.course-eligibility__add-course-eligibility__score {
        line-height: 1em;
        padding-left: 8px;
        width: 47px;
        .input-field {
          margin-top: -2px;
          input {
            font-size: 12px;
            width: 100%;
            padding: 7px 8px;
            text-align: center;
            border: 1px solid #e1e1e1;
          }
        }
      }
      &.course-eligibility__add-course-eligibility__add-button {
        line-height: 1em;
        font-size: 12px;
        width: 60px;
        text-align: center;
      }
    }
  }
  .collapsible {
    border-bottom: none;
    .collapsible__header .collapsible__header__text {
      padding-left: 29px;
      height: $theme-my-course-plans-item-height;
      &:before {
        margin-right: 15px;
        margin-top: -1px;
        border-left-width: 6px;
        border-right-width: 6px;
        border-top-width: 6px;
      }
      &.collapsible__header__text--hide {
        padding-left: 34px;
        &:before {
          margin-right: 10px;
          border-top-width: 6px;
          border-bottom-width: 6px;
          border-left-width: 6px;
        }
      }
    }
    .collapsible__header .collapsible__header__actions {
      padding-right: 15px;
    }
  }
  .course-eligibility__header {
    font-size: 15px;
    font-weight: 300;
    line-height: $theme-my-course-plans-item-height;
    height: $theme-my-course-plans-item-height;
  }
  .collapsible__content {
    .collapsible__content-resizer {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .course-eligibility__vce-subject-count {
    font-size: 12px;
    font-weight: 300;
  }
  .course-eligibility__added-vce-subjects {

    .course-eligibility__added-vce-subjects__vce-subject {
      background-color: #f1f1f1;
      padding: 10px;
      font-size: 12px;
      display: table;
      width: 100%;
      .course-eligibility__added-vce-subjects__vce-subject__name,
      .course-eligibility__added-vce-subjects__vce-subject__score,
      .course-eligibility__added-vce-subjects__vce-subject__remove-button {
        display: table-cell;
        padding: 5px 0px;
        text-align: center;
        font-weight: lighter;
      }
      .course-eligibility__added-vce-subjects__vce-subject__name {
        text-align: left;
        width: auto;
        padding-left: 16px;
      }
      .course-eligibility__added-vce-subjects__vce-subject__score {
        width: 47px;
        padding-left: 9px;
      }
      .course-eligibility__added-vce-subjects__vce-subject__remove-button {
        width: 60px;
      }
    }
  }
}

// Safari only styles
.course-planner--Safari {
  .course-eligibility {
    .course-eligibility__add-course-eligibility {
      .course-eligibility__add-course-eligibility__name {
        .ant-select-dropdown {
          margin-top: 4px;
        }
        .course-eligibility__add-course-eligibility__item.course-eligibility__add-course-eligibility__score {
          .input-field {
            margin-top: 0px;
          }
        }
      }
    }
  }
}
