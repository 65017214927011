@import "./../theme/style/theme";

@page {
  size: A4;
  margin: 1cm 20px;
}

@media print {
  .print-view {
    //.print-view__header {
    //  position: fixed;
    //  width: 100%;
    //  top: 0;
    //}
    //.print-view__footer {
    //  position: fixed;
    //  width: 100%;
    //  bottom: 0;
    //}
    //.print-view__content {
    //  width: 100%;
    //  //margin-top: 100px;
    //  margin-bottom: 50px;
    //}
  }
}

.print-view {
  font-size: 10px;
  font-family: 'roboto';
  border-left: 1px solid $theme-border-color-dark;
  border-right: 1px solid $theme-border-color-dark;
  border-bottom: 1px solid $theme-border-color-dark;
  width: 595px;
  margin: 0 auto;
  .print-view__header {
    background: #0058a6;
    padding: 0 38px;
    padding-bottom: 20px;
    color: #ffffff;
    position: relative;
    .print-view__header__logo-and-legend {
      .print-view__header__logo {
        width: 70px;
        height: 70px;
        background: url("../theme/img/logo-4d389.svg");
        background-color: #004576;
        background-size: 54px;
        background-position: center;
        background-repeat: no-repeat;
        float: left;
      }
      .print-view__legends {
        display: table;
        float: right;
        margin-top: 27px;
        .print-view__legends__text {
          display: inline-block;
          margin-right: 15px;
          float: left;
          line-height: 15px;
        }
        .print-view__legends__color-indicator {
          width: 15px;
          height: 15px;
          float: left;
          line-height: 15px;
          margin-right: 10px;
        }
        .print-view__legends__compulsory {
          display: table-cell;
          .print-view__legends__color-indicator {
            background: $theme-color-compulsory;
          }
        }
        .print-view__legends__elective {
          display: table-cell;
          .print-view__legends__color-indicator {
            background: $theme-color-elective;
          }
        }
        .print-view__legends__breadth {
          display: table-cell;
          .print-view__legends__color-indicator {
            background: $theme-color-breadth;
          }
        }
      }
    }

    .print-view__header__course-title {
      font-size: 16px;
      font-weight: 300;
      position: relative;
      padding-top: 10px;
      .print-view__header__course-title__major {
        font-weight: lighter;
        font-size: 12px;
        padding-top: 5px;
      }
    }
  }

  .print-view__content {
    padding: 10px 0px;
    .print-view__course-year {
      page-break-inside: avoid;
      padding: 0px 38px;
      padding-bottom: 20px;
      &:first-child {
        .print-view__course-year__border {
          border-top: none;
        }
      }
      .print-view__course-year__border {
        height: 0px;
        padding-bottom: 15px;
        border-top: 1px solid $theme-border-color-dark;
      }
      .print-view__course-year__title {
        font-size: 16px;
        font-weight: lighter;
        padding-bottom: 15px;
      }
      .print-view__course-year__course-semester {
        font-weight: 300;
        clear: both;
        .print-view__course-year__course-semester__title {
          text-transform: uppercase;
          padding-bottom: 3px;
        }
        .print-view__course-year__course-semester__subject {
          padding: 4px 5px;
          width: 120px;
          height: 65px;
          margin-right: 8px;
          margin-bottom: 8px;
          float: left;
          color: #ffffff;
          &:last-child {
            margin-right: 0px;
          }
          &.print-view__course-year__course-semester__subject--compulsory {
            background: $theme-color-compulsory;
          }
          &.print-view__course-year__course-semester__subject--elective {
            background: $theme-color-elective;
          }
          &.print-view__course-year__course-semester__subject--breadth {
            background: $theme-color-breadth;
          }
          .print-view__course-year__course-semester__subject-code {
            font-weight: 300;
            text-decoration: underline;
          }
          .print-view__course-year__course-semester__subject-name {
            font-weight: lighter;
          }
        }
      }
    }
  }
}
