@import '../../theme/style/theme';

.outcomes {
  background-color: $theme-outcome-background-color;
  position: relative;

  .outcomes__header {
    display: table;
    width: 100%;
    height: 61px;
    padding: 0 20px;
    background: $theme-outcome-header-background-color;
    position: relative;
    &:before {
      content: " ";
      position: absolute;
      width: 100%;
      border-top: 1px solid $theme-rule-dark-color;
      border-bottom: 1px solid $theme-rule-light-color;
      left: 0px;
      top: 0px;
    }
    &:after {
      content: " ";
      position: absolute;
      width: 100%;
      border-top: 1px solid $theme-rule-dark-color;
      border-bottom: 1px solid $theme-rule-light-color;
      left: 0px;
      bottom: 0px;
    }
    .outcomes__header-text {
      display: table-cell;
      //width: 1%;
      padding-right: 20px;
      font-size: $theme-header-text-size-default;
      font-weight: $theme-header-text-weight-default;
      letter-spacing: $theme-header-text-letter-spacing-default;
      white-space: nowrap;
      vertical-align: middle;
    }
    .outcomes__outcome-selections {
      display: table-cell;
      vertical-align: middle;
      color: $theme-text-color-light;
      font-weight: lighter;
      .outcomes__outcome-selection {
        padding: 0 12px;
        &.outcomes__outcome-selection--selected {
          color: $theme-text-color-default;
        }
      }
    }
  }
  .outcomes__plan-messages {
    padding: 10px 20px;
    > .message:first-child {
      margin-top: 10px;
    }
    > .message:last-child {
      margin-bottom: 0px;
    }
  }

  //&.outcomes--plan-has-error {
  //  .outcomes__panel-wrapper {
  //    position: relative;
  //    .outcomes__panel-wrapper__error-overlay {
  //      display: block;
  //    }
  //  }
  //}

  .outcomes__panel-wrapper {
    padding: 20px;
    padding-top: 10px;
    .outcomes__my-majors-panel {
      padding-bottom: 5px;
    }
    .outcomes__select-major-panel {
    }
    //.outcomes__panel-wrapper__error-overlay {
    //  position: absolute;
    //  z-index: 1;
    //  width: 100%;
    //  height: 100%;
    //  display: none;
    //  background: #ffffff;
    //  opacity: 0.6;
    //  transition: opacity 400ms ease-out;
    //  overflow: hidden;
    //  top: 0px;
    //  left: 0px;
    //}
  }

  &.outcomes--loading {
    overflow: hidden;
    .outcomes__spinner {
      opacity: 1;
      transition: opacity 10ms ease-in
    }
  }

  .outcomes__spinner {
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 100%;
    display: none;
    opacity: 0;
    transition: opacity 350ms ease-out;
    overflow: hidden;
    top: 0px;
    left: 0px;
    .outcomes__spinner-overlay {
      background: #ffffff;
      opacity: 0.6;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
    .outcomes__spinner-icon {
      display: none;
      position: absolute;
      top: calc(50% - 40px);
      left: calc(50% - 80px);
      overflow: hidden;
    }

    &.outcomes--loading {
      .outcomes__spinner {

      }
    }
  }
}
