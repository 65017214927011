@import '../../theme/style/theme';

.browse-subjects {
  min-height: 1000px;

  .browse-subjects__header {
    display: table;
    width: 100%;
    height: 60px;
    padding: 13px 20px;
    position: relative;
    //border-bottom: 1px solid #cccccc;
    background: $theme-browse-subjects-header-background-color;
    &:before {
      content: " ";
      position: absolute;
      width: 100%;
      border-top: 1px solid $theme-rule-dark-color;
      border-bottom: 1px solid $theme-rule-light-color;
      left: 0px;
      top: 0px;
    }
    &:after {
      content: " ";
      position: absolute;
      width: 100%;
      border-top: 1px solid $theme-rule-dark-color;
      border-bottom: 1px solid $theme-rule-light-color;
      left: 0px;
      bottom: 0px;
    }
    &.browse-subjects--sticky {
      position: fixed;
      top: 0px;
      background: #ffffff;
      z-index: 100;
      border-right: 1px solid $theme-border-color-light;
      border-bottom: 1px solid #fff;
      box-shadow: 0px 2px 4px $theme-outcome-bricks-box-shadow;
      //height: 72px;
      width: 100%;
      &:before, &:after {
        display: none;
      }
    }
    .browse-subjects__header-text {
      display: table-cell;
      width: 1%;
      padding-right: 20px;
      font-size: $theme-header-text-size-default;
      font-weight: $theme-header-text-weight-default;
      letter-spacing: $theme-header-text-letter-spacing-default;
      white-space: nowrap;
      vertical-align: middle;
    }
    .browse-subjects__filters {
      display: table-cell;
      vertical-align: middle;
      .browse-subjects__filter-spacer {
        width: 2px;
        margin: 0 5px;
        display: inline-block;
        line-height: 18px;
        margin: 0 5px;
        background: $theme-text-color-default;
      }
      .browse-subjects__filter {
        display: inline-block;
        padding-left: 8px;
        padding-right: 8px;
        font-size: 16px;
        user-select: none;
        &:last-child {
          border-right: none;
        }
        &.browse-subjects__filter--selected {
          a.clickable {
            font-weight: bold;
            color: $theme-text-color-default;
          }
        }
        &.browse-subjects__filter--disabled {
          pointer-events: none;
          opacity: 0.5;
        }
        a.clickable {
          color: $theme-text-color-light;
        }

        //&.browse-subjects__filter-breadth {
          a.clickable {
            display: inline-block;
            .browse-subjects__filter-wrapper {
              display: table;
              overflow: visible;
              > span {
                display: table-cell;
                line-height: 12.5px;
                vertical-align: middle;
                &.material-icons {
                  font-size: 18px;
                  padding-right: 3px;
                }
              }
            }
          }
        //}
      }
    }
  }
}
