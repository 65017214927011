@import '../../../theme/style/theme';

$text-color: #ffffff;
$background-color: $theme-dialog-background-color;
$content-max-height: 650px;

.major-details {
  float: left;
  clear: both;
  max-height: $theme-dialog-indicator-size + $content-max-height;
  margin-bottom: 6px;
  width: 100%;

  -webkit-transition: all 1ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 1ms cubic-bezier(0.23, 1, 0.32, 1);

  &.major-details--loading {
    max-height: 0;

    -webkit-transition: all 1ms cubic-bezier(0.23, 1, 0.32, 1);
    transition: all 1ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  .major-details__indicator {
    position: relative;
    z-index: 1;
    border-left: $theme-dialog-indicator-size solid transparent;
    border-right: $theme-dialog-indicator-size solid transparent;
    border-bottom: $theme-dialog-indicator-size solid $background-color;
    margin-left: $theme-dialog-indicator-size * -1;
    margin-top: 1px;
    width: $theme-dialog-indicator-size;
    height: $theme-dialog-indicator-size;
  }
  .major-details__resizer {
    min-height: 220px;
    max-height: $content-max-height;
    padding: 15px 20px;
    background-color: $background-color;
    font-weight: lighter;
    color: $text-color;
    display: grid;
    grid-template-columns: auto 330px;
    grid-template-rows: 40px auto;

    .major-details__header {
      grid-column: 1/2;
      grid-row: 1/2;

      display: inline-block;
      font-size: $theme-header-text-size-default;
      font-weight: $theme-header-text-weight-default;
      letter-spacing: $theme-header-text-letter-spacing-default;
      margin-top: 5px;
    }

    .message {
      grid-column: 1/3;
    }

    .major-details__content__overview {
      grid-column: 1/3;
      padding-right: 20px;
      font-size: 14px;
      .major-details__content__overview-header {
        font-weight: bold;
        padding-bottom: 5px;
      }
      .major-details__content__overview-content {
        max-height: $content-max-height - 100;
        overflow: hidden;

        .major-details__content__overview-content__more {
          display: block;
          text-decoration: underline;
          color: $text-color;
          font-weight: normal;
          padding: 15px 10px;
          padding-left: 0px;
        }
      }
    }
    //.major-details__content__potential-careers {
    //  grid-column: 2/3;
    //  padding-left: 20px;
    //  border-left: 2px solid $theme-border-color-dark;
    //  font-size: 14px;
    //  .major-details__content__potential-careers-header {
    //    font-weight: bold;
    //    padding-bottom: 5px;
    //  }
    //  .major-details__content__potential-careers-content {
    //
    //  }
    //}
  }
}
