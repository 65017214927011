@import '../theme/style/theme';

.header-banner {
  height: 103px;
  background-color: rgb(9, 65, 131);
  margin-bottom: 25px;

  a {
    color: inherit;
    font-weight: bold;
    text-decoration: underline;
  }

  .message--warn h2 {
    color: inherit;
    font-size: larger;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  p {
    margin-bottom: 0.5rem;
  }

  ul {
    list-style: disc;
    margin-left: 30px;
    margin-bottom: 0.5rem;
  }

  &--notifications {
    font-size: $theme-text-size-default;
    font-family: 'roboto';
    height: auto;
    background-color: transparent;
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;

    .message {
      margin: 0
    }
  }
}

.course-planner {
  position: relative;
  width: 100%;
  font-size: $theme-text-size-default;
  font-family: 'roboto';
  padding: 0;
  margin: 0;
  line-height: 1.3em;
  color: $theme-text-color-default;
  height: 100%;

  a {
    text-decoration: none;
    color: $theme-text-color-default;
  }

  .subject-details a {
    text-decoration: none;
    color: $theme-link-color;
  }

  button {
    border: 1px solid $theme-border-color-dark;
    color: $theme-text-color-default;
    font-size: 14px;
    margin-right: 8px;
    padding: 8px 12px;
    font-weight: lighter;
  }

  input {
    border: 1px solid $theme-border-color-dark;
    font-size: 14px;
    line-height: 16px;
    padding: 10px 20px;
    font-weight: lighter;
  }

  // specific components
  &.course-planner--loading {
    overflow: hidden;
    .course-planner__spinner {
      opacity: 1;
      transition: opacity 400ms ease-in
    }
  }

  .course-planner__spinner {
    position: absolute;
    grid-column: 1/3;
    z-index: 999;
    width: 100%;
    height: 100%;
    display: none;
    opacity: 0;
    transition: opacity 400ms ease-out;
    overflow: hidden;
    .course-planner__spinner-overlay {
      background: #ffffff;
      opacity: 0.6;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    .course-planner__spinner-icon {
      position: fixed;
      top: calc(50% - 40px);
      left: 50%;
      overflow: hidden;
    }
  }

  &.course-planner--layout-main {
    display: grid;
    grid-template-columns: minmax(420px, 420px) 1fr;
    grid-template-rows: auto 1fr;
    grid-column-gap: 0;
    grid-row-gap: 0;
    max-width: 1440px;
    margin: 0 auto;
  }

  .course-planner__section {
  }

  .course-planner__course-plans {
    border-left: 1px solid $theme-border-color-dark;
    //border-right: 1px solid $theme-border-color-dark;
    border-top: 0px;
    grid-row: 1/3;
    grid-column: 1/2;
    //background: $theme-alter-item-background-color;
    box-shadow: 0px 2px 4px 0 #9B9B9B;
    z-index: 100;
    width: 420px;
    position: relative;
  }
  .course-planner__outcomes-and-subjects {
    right: 0;
    height: 100%;
    overflow: auto;
    .course-planner__outcomes {
      border-right: 1px solid $theme-border-color-dark;
      min-width: 820px;
    }

    .course-planner__browse-subjects {
      border-top: 0;
      border-left: 0;
      background-color: $theme-browse-subjects-background-color;
      border-right: 1px solid $theme-border-color-dark;
      min-height: 600px;
      min-width: 820px;
    }
  }

  &.course-planner--loading {
    .course-planner__browse-subjects {
      overflow: hidden;
    }
  }
}
