@import "../../../../../theme/style/theme";

.course-semester {
  display: block;
  overflow: visible;
  &.course-semester--no-subjects {
    max-height: 0px;
    visibility: collapse;
  }
  &.course-semester--not-droppable {
    opacity: 0.2!important;
  }
  .course-semester-subjects {
    min-height: $theme-my-course-plans-item-height;
    overflow: visible;
    position: relative;
    &.course-semester-subjects--draggable-over {
      position: relative;
      background: $theme-my-course-plans-droppable-background-color;
    }
    &.course-semester-subjects--not-droppable {
      pointer-events: none;
    }
  }
  .course-semester__header {
    overflow: hidden;
    font-size: 14px;
    font-weight: $theme-text-weight-medium;
    position: relative;
    padding-bottom: 5px;
    padding-top: 15px;
    z-index: 1;

    .course-semester__header__semester {
      font-weight: 300;
    }
    .course-semester__clear {
      float: right;
      font-weight: lighter;
      font-size: 12px;
      padding-top: 2px;
      .course-semester__clear__wrapper {
        display: table;
        line-height: 24px;
        margin-top: -5px;
        color: $theme-text-color-light;
        .course-semester__clear__text {
          display: table-cell;
          vertical-align: middle;
          line-height: 24px;
        }
        > .material-icons {
          //display: table-cell;
          vertical-align: middle;
          display: none;
        }
      }
    }
  }
}
