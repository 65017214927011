@import './../../../../../theme/style/theme';


.selected-subject-header-header {
  height: $theme-my-course-plans-item-height;
  padding: 4px 0;
  padding-left: 0px;
  padding-right: 8px;
  display: grid;
  cursor: grab;
  position: relative;
  grid-template-columns: 50px auto auto 24px;
  &:before {
    content: " ";
    width: 10px;
    height: calc(100% - 4px);
    position: absolute;
    left: 0;
    top: 0;
  }
  &.selected-subject-header__subject-category--compulsory:before {
    background-color: $theme-color-compulsory;
  }
  &.selected-subject-header__subject-category--elective:before {
    background-color: $theme-color-elective;
  }
  &.selected-subject-header__subject-category--breadth:before {
    background-color: $theme-color-breadth;
  }

  &.selected-subject-header--html-dragging {
    border: 1px solid $theme-border-color-dark;
  }
  &.selected-subject-header--drop-allowed {
  }
  .selected-subject-header__subject-status {
    grid-row: 1/3;
    grid-column: 1;
    margin-top: 7px;
    padding-left: 18px;
    padding-right: 8px;
    .selected-subject-header__subject-status--met {
      color: $theme-my-course-plans-subject-met-color;
    }
    .selected-subject-header__subject-status--unmet {
      color: $theme-my-course-plans-subject-unmet-color;
    }
    .selected-subject-header__subject-status--open {
      color: $theme-my-course-plans-subject-open-color;
    }
  }
  .selected-subject-header__subject-name {
    grid-column: 2/4;
    font-weight: lighter;
    line-height: 18px;
    padding-top: 1px;
    max-width: calc(100% - 5px);
  }
  .selected-subject-header__subject-code {
    grid-column: 2;
    font-size: 13px;
    line-height: 1em;
    padding-bottom: 0px;
    font-weight: $theme-text-weight-medium;
    color: $theme-link-color;
  }
  .selected-subject-header__subject-semesters {
    grid-column: 3;
    font-size: 13px;
    line-height: 1em;
    padding-bottom: 0px;
    font-weight: $theme-text-weight-medium;
    padding-right: 5px;
    text-align: right;
    color: #99C24D;
    text-transform: uppercase;
    &.selected-subject-header__subject-semesters-invalid {
      color: $theme-my-course-plans-subject-unmet-color;
    }
  }
  .selected-subject-header__subject-remove-button {
    grid-row: 1/3;
    grid-column: 4;
    margin-top: 6px;
    a.clickable {
      font-size: 0;
      display: table-cell;
      color: $theme-color-remove;
    }
  }
}