@import "../../../../theme/style/theme";

.collapsible {
  border-bottom: 1px solid $theme-border-color-dark;
  //padding-left: 20px;
  //padding-right: 20px;

  .collapsible__header {
    display: table;
    width: 100%;

    &.collapsible__header_custom-indicator {
      .collapsible__header__text:before {
        display: none;
      }
      .collapsible__header__text {
        padding-left: 0;
      }
    }
    .collapsible__header__text {
      padding-right: $collapsible-item-padding;
      padding-left: $collapsible-item-padding-large;
      user-select: none;
      white-space: nowrap;
      cursor: pointer;
      display: table-cell;
      font-size: $theme-header-text-size-default;
      font-weight: $theme-header-text-weight-default;
      letter-spacing: $theme-header-text-letter-spacing-default;
      height: 60px;
      vertical-align: middle;
    }
    .collapsible__header__text:before {
      content: ' ';
      width: 0;
      margin-right: 10px;
      margin-top: -4px;
      vertical-align: middle;
      display: inline-block;
      color: $theme-color-blue;

      border-left: $collapsible-item-arrow-size solid transparent;
      border-right: $collapsible-item-arrow-size solid transparent;
      border-top: $collapsible-item-arrow-size solid $theme-color-blue;
      margin-left: -$collapsible-item-arrow-size;
    }
    .collapsible__header__text--hide:before {
      border-top: $collapsible-item-arrow-size solid transparent;
      border-bottom: $collapsible-item-arrow-size solid transparent;
      border-left: $collapsible-item-arrow-size solid $theme-color-blue;
    }
    .collapsible__header__actions {
      padding-right: $collapsible-item-padding-large;
      display: table-cell;
      white-space: nowrap;
      width: 1%;
      vertical-align: middle;
    }
  }
  .collapsible__content {
    overflow: hidden;
    // IMPORTANT: do not add max-height here, otherwise animation will not work properly.
    //            the max-height is set programmatically in the component
    border-top: 1px solid $theme-border-color-dark;
    //transition: max-height 300ms ease-in;
    -webkit-transition: all 130ms linear;
    transition: all 130ms linear;

    .collapsible__content-resizer {
    }
    &.collapsible__content--hide {
      padding-top: 0;
      padding-bottom: 0;
      max-height: 0px !important;
      //transition: max-height 300ms ease-out;
      -webkit-transition: all 130ms linear;
      transition: all 130ms linear;
    }
  }
}
