@import "./../../theme/style/theme";

.my-course-plan {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  width: 419px;
  &.my-course-plan--sticky {
    position: fixed;
    top: 0px;
    box-shadow: 0px 2px 4px 0 #9B9B9B;
    background: #ffffff;
  }
  .my-course-plan__header {
    padding: 10px $collapsible-item-padding-large;
    line-height: $theme-item-height-default;
    font-size: $theme-header-text-size-default;
    font-weight: $theme-header-text-weight-default;
    letter-spacing: $theme-header-text-letter-spacing-default;
    height: 61px;
    display: table;
    grid-template-columns: auto auto;
    border-bottom: 1px solid $theme-border-color-dark;
    border-top: 1px solid $theme-border-color-dark;
    > .my-course-plan__header-text {
      display: table-cell;
      font-size: $theme-header-text-size-default;
      font-weight: $theme-header-text-weight-default;
      letter-spacing: $theme-header-text-letter-spacing-default;
      line-height: $theme-item-height-default;
    }
    > .my-course-plan__course-progress {
      width: 1%;
      white-space: nowrap;
      font-size: 14px;
      font-weight: $theme-text-weight-medium;
      display: table-cell;
      text-align: right;
      vertical-align: middle;
      .my-course-plan__course-progress-status {
        font-weight: lighter;
      }
    }
  }
  .my-course-plan__course {
    display: table;
    width: 100%;
    min-height: $theme-my-course-plans-item-height;
    .collapsible {
      .collapsible__header {
        .collapsible__header__actions {
          padding-right: 17px;
          //border-bottom: 1px solid $theme-border-color-light;
        }
      }
    }
    .my-course-plan__course-header__clear-all {
      display: table;
      color: $theme-text-color-light;
      font-weight: lighter;
      .my-course-plan__course-header__clear-all__text {
        font-size: 12px;
        line-height: 24px;
        display: table-cell;
        vertical-align: middle;
      }
      .material-icons {
        //display: table-cell;
        vertical-align: middle;
        display: none;
      }
    }
    &.my-course-plan__course--complete {
      .my-course-plan__course-status {
        color: #99c24d;
        height: 24px;
        width: 24px;
        background-color: #ffffff;
        font-size: 24px;
        margin-top: 1px;
      }
      .my-course-plan__course-message-panel {
        background-color: $theme-color-select;
      }
    }

    &.my-course-plan__course--in-progress {
      .my-course-plan__course-status {
        color: #ffffff;
        height: 20px;
        width: 20px;
        background-color: $theme-color-select;
        border-radius: 50%;
      }
      .my-course-plan__course-message-panel {
        background-color: $theme-color-select;
      }
    }
    &.my-course-plan__course--invalid {
      .my-course-plan__course-status {
        color: #ffffff;
        height: 20px;
        width: 20px;
        background-color: $theme-my-course-plans-subject-unmet-color;
        border-radius: 50%;
      }
      .my-course-plan__course-message-panel {
        background-color: $theme-my-course-plans-subject-message-panel-unmet-color;
      }
    }

    > .collapsible {
      border-bottom: none;

      > .collapsible__content {
        border-top: 1px solid $theme-border-color-dark;
        border-bottom: 1px solid $theme-border-color-dark;
        &.collapsible__content--hide {
          border-bottom: none;
        }
      }
      .collapsible__header {

        position: relative;
        .collapsible__header__text {
          height: $theme-my-course-plans-item-height;
          //border-bottom: 1px solid $theme-border-color-light;
          &:before {
            display: none;
          }
        }
        .my-course-plan__course-header {
          .my-course-plan__course-title {
            font-size: 15px;
            display: table-cell;
            vertical-align: middle;
            font-weight: $theme-text-weight-medium;
            padding-left: 10px;
          }
          .my-course-plan__course-status {
            display: table-cell;
          }
        }
      }
      .my-course-plan__course-message-panel {
        font-size: 14px;
        padding: 20px;
        padding-left: 50px;
        border-bottom: 1px solid $theme-border-color-light;
        .my-course-plan__course-message-panel__headline {
          padding-bottom: 18px;
        }
        .my-course-plan__course-message-panel__details {
          font-weight: lighter;
          .my-course-plan__course-message-panel__details_item {
            padding-bottom: 5px;
          }
        }
      }
    }
  }
  .my-course-plan__course-progress-bar {
    height: 10px;
    position: relative;
    //border-bottom: 1px solid $theme-border-color-dark;
    background: $theme-my-course-plans-progress-background-color;
  }
  .my-course-plan__course-progress-completeness {
    background: $theme-color-select;
    height: 100%;
  }
}
