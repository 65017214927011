@import '../../../../../theme/style/theme';

.collapsible-list-item {
  border-bottom: 1px solid $theme-alter-item-border-color;
  &:nth-child(even) {
    background-color: $theme-alter-item-background-color;
  }
  .collapsible {
    border: none;
    .collapsible__header {
      .collapsible__header__text {
        font-size: 16px;
        font-weight: 500;
        color: $theme-color-blue;
        height: $theme-item-height-default;
        line-height: 1em;
        &:before {
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 6px solid $theme-color-blue;
          margin-right: 6px;
        }
        &.collapsible__header__text--hide:before {
          border-top: 6px solid transparent;
          border-bottom: 6px solid transparent;
          border-left: 6px solid $theme-color-blue;
          margin-left: -6px;
          margin-right: 4px;
        }
      }

    }
    .collapsible__content {
      border: none;
      &.collapsible__content--hide {
      }
    }
  }
}


