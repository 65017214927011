
.available-subjects {
  padding: 20px;
  &.loading {
    .available-subjects__loading-icon {
      display: block;
    }
  }
  .collapsible-list {
    margin-bottom: 10px;
  }
  .available-subjects__loading-icon {
    padding-left: 50%;
    margin-left: -34px;
    display: none;
    overflow: hidden;
  }
}