@import '../../../theme/style/theme';

$show-hide-indicator-color: #8a8a8a;

.available-majors {
  position: relative;
  .available-majors__header {
    font-weight: bold;
    padding-bottom: 2px;
    cursor: pointer;
    &:hover:after {
      text-decoration: underline;
    }
    &:after {
      content: "HIDE";
      padding-left: 6px;
      font-size: 12px;
      font-weight: lighter;
      line-height: 22px;
      color: $show-hide-indicator-color;
      display: inline-block;
      position: absolute;
    }
  }
  .collapsible {
    border: none;
    .collapsible__header {
      width: 140px;
      table-layout: fixed;
      .collapsible__header__text {
        height: auto;
        padding-left: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        &.collapsible__header__text--hide {
          .available-majors__header:after {
            content: "SHOW";
          }
        }
      }
    }
    .collapsible__content {
      border: none;
    }
  }
  .available-majors__selection {
    overflow: auto;
    padding-bottom: 2px;
    position: relative;
  }
  &.available-major--hide-all-majors {
    .available-majors__selection {
      height: 0px!important;
    }
  }
}


